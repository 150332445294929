import './style.scss'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import BrandSection from '../../components/brandSection'
import ProjectCards from '../../components/projectCards'
import FeatureCards from '../../components/featureCards'
import ContactSection from '../../components/contactSection'
import Footer from '../../components/footer'
import ServiceCards from '../../components/serviceCards'
import NavBar from "../../components/navbar"

const Entreprenuer = () => {
    return(
        <>
            <Helmet>
                <title>Stact - Personal Portfolio</title>
            </Helmet>
            <NavBar/>
            <div className="portfolio-container landing-1 saas-landing">
                <Hero/>
                <About/>
                <Showcase/>
                <Counters/>               
                <Features/>
                <Showcase2/>
                <Services/> 
                
                <InfoSection/>
				<Showcase1/> 
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>
            <section className="section-global bg-shade-green hero">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-xl-4 col-lg-5 align-self-center">
                            <h1>Empowering Entrepreneurs </h1>
							<h4>Transforming Start-up Dreams </h4>
                            <p className='txt-1 mb-20 mt-16'>We love entrepreneurs! Your journey is awesome, and we're here to help your start-up dreams become reality. </p>
                            <Link to='/' className='btn btn-primary btn-lg'>Read More</Link>
                        </div>
                        <div className="col-xl-8 col-lg-7 align-self-center d-flex justify-content-center text-center">
                            <div style={{maxWidth: '650px'}}>
                                <img src={require('./assets/hero_image.png')} className="img-fluid" alt='hero' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const About = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <h2>What is an Entrepreneur? </h2>
                        </div>
                        <div className="col-lg-8">
                            <p className='txt-1'>
                                Entrepreneurs don't just open regular businesses; they create something amazing out of nothing. They see opportunities where others might not and then use their creativity and hard work to turn these ideas into reality, and that's what makes entrepreneurs so special.
                                <br/><br/>
                               They're like inventors who create cool stuff that didn't exist before. It's about starting with empty space and creating something valuable and awesome.
 <br/><br/>
So, being an entrepreneur is about bringing fresh, cool stuff into the world by using their creativity and hard work.

                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Our Love for Entrepreneurs</div>
                            <h2 className='mb-16 display-2'>Why we help and are fascinated by the entrepreneurial spirit </h2>
                            <p className='txt-1'>We're big fans of entrepreneurs. We get excited about your cool ideas and the hard work you put into making them real. We're here to help you succeed and make your start-up dreams come true. We'll help you tackle problems and find solutions. From thinking up ideas to making them real, we'll be there cheering you on and celebrating your wins.</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Your Vision, Our Skills</div>
                            <h2 className='mb-16 display-2'>Transform your vision into success: Let us showcase our skills</h2>
                            <p className='txt-1'>We've got a bunch of talents that can help your start-up shine. We're great at making websites, setting up online stores, getting the word out on the internet, creating mobile apps, making things look awesome, and managing ads that get you noticed. We've got all these cool tools to make your start-up awesome. </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Counters = () => {
    const counter_data = [
        {
            count: '1K+',
            text: 'Delivered projects'
        },
        {
            count: '05',
            text: 'Years of experience'
        },
        {
            count: '09',
            text: 'Awards won'
        },
        {
            count: '900',
            text: 'Designs delivered'
        }
    ]
    return (
        <>
            <section className='counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}
const Services = () => {

    const services = [
        {
            name: 'Web Development',
            info: 'Our team of web developers creates amazing websites that are customised to meet your needs. ',
            link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Digital Marketing',
            info: 'With our comprehensive digital marketing services, we help businesses in reaching their target audience.',
            link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Mobile App Development',
            info: 'Realize the full potential of mobile technology with our top-notch mobile app development services. ',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Graphic Design',
            info: 'Enhance your brands image with visually appealing graphic designs crafted by our skilled designers.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-1'>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-4">
                            <div className="section-tag mb-8">Services</div>
                            <h2 className='mb-16'>Services we provide to entrepreneurs</h2>
                            <Link to='/services' className='btn btn-outline btn-arrow'>
                                See all services
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                        <div className="col-lg-8">
                            <div className="row gx-md-5 gy-5">
                                {
                                    services.map((service,i) => 
                                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6"  key={i}>
                                            <ServiceCards data={service} src="landing3"/>
                                        </div>                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase2 = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Teamwork </div>
                            <h2 className='mb-16 display-2'>Strategies for effective teamwork and achieving results </h2>
                            <p className='txt-1'>Starting a business can be tough, but we've got your back. We're like your buddies on this journey. We want to hear all about your ideas and what you want to do. Together, we can figure out the best way to make your ideas work online. We'll use our smarts to help your smarts, and we'll work together every step of the way. </p>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}
const Showcase1 = () => {
    return(
        <>
            <section className='section-global pt-4' style={{border: 'none'}}>
                <div className="container container-2">
                    {/* <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Communicate</div>
                            <h2 className='mb-16 display-2'>Communicate with other people in the same space</h2>
                            <p className='txt-1'>Cross-platform messaging allows people from iOS/Android to communicate with one another using a universal library of smiley faces, threads, and file sharing. Everyone wins!</p>
                        </div>
                    </div> */}
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Lets Begin</div>
                            <h2 className='mb-16 display-2'>Start your start-up journey</h2>
                            <p className='txt-1'>Ready to take the plunge? Just give us a shout, tell us what you're thinking, and we'll jump right in with you. We make things easy and fun, and we are excited to see your start-up soar with our help. </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Features = () => {

    const featurs_data = [
        {
            title: 'Tailored Strategies',
            text: 'Crafted uniquely for your goals, our customized strategies ensure your brand shines.'
        },
        {
            title: 'Creative Excellence',
            text: 'Our experts blend innovation and precision, crafting digital solutions that captivate and engage.'
        },
        {
            title: 'Data-Driven Results',
            text: 'Backed by insights, our iterative approach ensures measurable success and continuous improvement.'
        }
    ]

    return(
        <>
            <section className='section-global pb-0' style={{border: 'none'}}>
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">How we work</div>
                            <h2>Extremely smooth workflow with cosistency</h2>
                        </div>
                    </div>
                </div>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={featurs_data} src='about'/>
                    </div>
                </div>
            </section>
        </>
    )
}

 

const InfoSection = () => {

    const infoData = [
        {
            title: 'Experience',
            text: 'When you work with Salesboss, you are not just benefiting from our founders experience but also their personal dedication. '
        },
        {
            title: 'Passion ',
            text: 'Our founders passion for helping businesses thrive is reflected in our approach to working closely with clients. '
        },
        {
            title: 'Achieve Goals',
            text: 'Achieve your goals at Salesboss. We will guide you throughout your journey, ensuring you experience success and growth.'
        }
    ]

    return(
        <>
            <section className="section-global bg-shade-green">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Your partner in success</div>
                            <h2 className='mb-16'>Guided by our founder's 17-Year Journey</h2>
                            {infoData.map((e,i)=>
                                <div className='d-flex mb-16' key={i}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                        <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                        <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div>
                                        <h4 className='mb-0'>{e.title}</h4>
                                        <div className="txt-2">{e.text}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 text-center align-self-center">
                            <img src={require('./assets/info.png')} className="img-fluid info-banner-image" alt="Why stact" />                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let’s Build Your Product Together!",
            text: "Get a free discovery session and consulting to start your project today.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data}/>
        </>
    )
}
export default Entreprenuer