import Helmet from "react-helmet"
import ContactSection from "../../components/contactSection"
import FeatureCards from '../../components/featureCards'
import Footer from "../../components/footer"
import NavBar from "../../components/navbar"
import ServiceCards from '../../components/serviceCards'
import TestimonialCards from '../../components/testimonialCards'
import { Link } from 'react-router-dom'
import ProjectCards from '../../components/projectCards'
import './style.scss'

const Services = () => {
    return(
        <>
            <Helmet>
                <title>Stact - Services</title>
            </Helmet>
            <NavBar/>
            <div className="services-container saas-landing">
                <Hero/>
                <ServicesSection/>                
                <Features/>
                <Showcase/>
                <InfoSection />
                <Showcase2/>
                <Testimonials/>
                <Showcase3/>
           
                <Projects/>
                <Showcase4/>
                <Contact/>
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>  
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">Services</div>
                            <h1 className='display-1'>Crafting Excellence in Web Development, Ecommerce, Marketing, and More.</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const ServicesSection = () => {

    const services = [
        {
            name: 'Web Development',
            info: 'Transforming Ideas into Stunning Online Experiences.',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Ecommerce Solutions',
            info: 'Navigating the Online Marketplace for Your Success.',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Digital Marketing',
            info: 'Amplifying Your Presence in the Digital Arena.',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Mobile App Development',
            info: 'Crafting Engaging Mobile Solutions.',
            icoUrl: 'assets/service_ico4.svg'
        },
        {
            name: 'Graphic Design',
            info: 'Designing Visual Narratives that Resonate.',
            icoUrl: 'assets/service_ico5.svg'
        },
        {
            name: 'Paid Ads Management',
            info: 'Optimizing Your Digital Outreach for Maximum Impact.',
            icoUrl: 'assets/service_ico6.svg'
        },
        {
            name: 'Real Estate Services',
            info: 'From real estate websites to digital marketing, we handle it all.',
            icoUrl: 'assets/service_ico7.svg'
        },
        {
            name: 'Integrated Sales & Marketing',
            info: 'Streamline sales, leads, and marketing with our All-In-One CRM.',
            icoUrl: 'assets/service_ico8.svg'
        }
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container">
                <div class="row mb-40"><div class="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center"><div class="section-tag mb-8">Services</div><h2>Best in class innovative tech solutions</h2></div></div>
                    <div className="row gx-md-5 gy-5">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6"  key={i}>
                                    <ServiceCards data={service} src="services"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">All-in-One CRM Solution </div>
                            <h2 className='mb-16 display-2'>When we design websites, you get more than just a fantastic online appearance </h2>
                            <p className='txt-1'>Get more than just a fantastic online appearance – you also gain the advantage of SalesBoss CRM. This robust platform simplifies managing contacts, leads, and marketing, enabling businesses to stay organized and effortlessly handle customer relationships. <br/>

SalesBoss CRM is like having a helpful assistant for managing your contacts, leads, and marketing efforts. This tool makes it simple to keep things organized and take care of your customer relationships in a smooth and efficient way.
</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Web Development</div>
                            <h2 className='mb-16 display-2'>Our team of web developers creates amazing websites</h2>
                            <p className='txt-1'>Our team of web developers creates amazing websites that are customised to meet your needs. We can build simple informational sites or complex web applications. We have the skills to deliver excellent results. </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Ecommerce</div>
                            <h2 className='mb-16 display-2'>We make e-commerce websites that help businesses sell things online easily. </h2>
                            <p className='txt-1'>Our websites have easy-to-use interfaces, safe payment systems, and inventory management. This makes shopping smooth and efficient for your customers. </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const InfoSection = () => {

    const infoData = [
        {
            title: 'Complete Solutions',
            text: 'Web, marketing, design – we do it all, simplifying your digital needs.'
        },
        {
            title: 'Tailored for You',
            text: 'Custom strategies ensure impactful, measurable results'
        },
        {
            title: 'Performance Matters',
            text: 'We focus on results, not just aesthetics.'
        },
	  {
            title: 'Ahead of Trends',
            text: 'We bring the latest tech to amplify your brand.'
        },
	  {
            title: 'Proven Expertise',
            text: '17 years experience ensures success at every step.'
        }
    ]

    return(
        <>
            <section className="section-global bg-shade-green">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Why choose SalesBoss?</div>
                            <h2 className='mb-16'>We provide solutions that make our clients lives simpler</h2>
                            {infoData.map((e,i)=>
                                <div className='d-flex mb-16' key={i}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                        <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                        <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div>
                                        <h4 className='mb-0'>{e.title}</h4>
                                        <div className="txt-2">{e.text}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 text-center align-self-center">
                            <img src={require('./assets/info.png')} className="img-fluid info-banner-image" alt="Why stact" />                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const Showcase2 = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Digital Marketing</div>
                            <h2 className='mb-16 display-2'>We help businesses in reaching their target audience and expanding their online presence. </h2>
                            <p className='txt-1'>Our strategies encompass search engine optimization (SEO), social media marketing, email campaigns, content creation, and analytics tracking, all focused on increasing brand visibility and driving targeted traffic to websites. </p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Mobile App Development</div>
                            <h2 className='mb-16 display-2'>Realize the full potential of mobile technology with our top-notch mobile app development services</h2>
                            <p className='txt-1'>Whether you need an Android or iOS app, we can design and develop intuitive and feature-rich applications that engage users while aligning with your business goals.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Graphic Design</div>
                            <h2 className='mb-16 display-2'>Enhance your brands image with visually appealing graphic designs crafted by our skilled designers. </h2>
                            <p className='txt-1'>We create captivating logos, brochures, banners, infographics, and more that effectively communicate your message and leave a lasting impression on your target audience.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const Features = () => {

    const featurs_data = [
        {
            title: 'Understanding',
            text: 'We thoroughly analyze your business, target audience, and market trends to develop our strategy.'
        },
        {
            title: 'Creating',
            text: 'Our skilled team works together to bring your ideas to life.'
        },
        {
            title: 'Growing',
            text: 'Once we start, we keep making things better. We use data to reach more people and have a bigger impact.'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">How we work</div>
                            <h2>Driving Your Digital Success</h2>
                        </div>
                    </div>
                </div>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={featurs_data} src='service'/>
                    </div>
                </div>
            </section>
        </>
    )
}


const Testimonials = () => {

    const testimonial_data = [
        {
            brandImgUrl: 'assets/review_brand1.svg',
            text: 'Love the product and the service, and the customer care team is awesome. The features are great, too--everything that you need.',
            userName: 'Mary Edwards',
            position: 'Product director',
            userImgUrl: 'assets/review_user1.svg'
        },
        {
            brandImgUrl: 'assets/review_brand2.svg',
            text: 'I recommend this product because it benefits everyone. You will be able to stay connected with your team and clients from all over the world.',
            userName: 'Felisa Rincon',
            position: 'Head of Product',
            userImgUrl: 'assets/review_user2.svg'
        },
        {
            brandImgUrl: 'assets/review_brand3.svg',
            text: 'I was able to get a fully functional online office space that included all the software we needed and it was super cheap!',
            userName: 'Eunice Kennedy',
            position: 'Product director',
            userImgUrl: 'assets/review_user3.svg'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-green'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Customer reviews</div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all reviews
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} src="saasLanding" />
                    </div>
                </div>
            </section>
        </>
    )
} 


const Showcase3 = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Paid Ads Management</div>
                            <h2 className='mb-16 display-2'>Maximize your return on investment with our professional paid ads management services.</h2>
                            <p className='txt-1'>Our team will meticulously plan and execute targeted ad campaigns across various platforms such as Google Ads, Facebook ads  and social media channels to drive conversions while staying within budget.</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Real Estate Services</div>
                            <h2 className='mb-16 display-2'>When it comes to real estate services, we have you covered. </h2>
                            <p className='txt-1'>Our expertise extends beyond just building stunning real estate websites - we provide a comprehensive range of digital marketing solutions tailored specifically for the real estate industry.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Boost Your Online Presence</div>
                            <h2 className='mb-16 display-2'>We create visually stunning websites that captivate your audience and leave a lasting impression. </h2>
                            <p className='txt-1'>Our designs not only provide an enjoyable user experience but also showcase the professionalism and credibility of your business.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data}/>
        </>
    )
}

const Projects = () => {

    const projects_data = [
        {
            category: 'Engaging Web Design',
            text: 'Our talented designers will create visually captivating websites that not only attract visitors but also provide a seamless user experience.',
            imgUrl: 'assets/project1.png',
            color: "#F97316",
            link: '/project-detail'
        },
        {
            category: 'Effective Social Media Marketing',
            text: 'Harness the power of social media platforms to reach a wider audience and build a loyal customer base. ',
            imgUrl: 'assets/project2.png',
            color: "#2D7EF8",
            link: '/project-detail'
        },
        {
            category: 'Drive more traffic',
            text: "Our user-friendly websites are optimized for search engines, helping your business rank higher in search results and attract more organic traffic.",
            imgUrl: 'assets/project3.png',
            color: "#00AA45",
            link: '/project-detail'
        }
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Our Work</div>
                            <h2>Our recently compeleted projects</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all case studies
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <ProjectCards data={projects_data} src="landing2" />
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase4 = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Engaging Web Design</div>
                            <h2 className='mb-16 display-2'>Our talented designers will create visually captivating websites</h2>
                            <p className='txt-1'>Our talented designers will create visually captivating websites that not only attract visitors but also provide a seamless user experience. We focus on creating responsive designs that adapt to different devices, ensuring that your brand stays consistent across desktops, tablets, and mobile phones.</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Effective Social Media Marketing</div>
                            <h2 className='mb-16 display-2'>Harness the power of social media platforms to reach a wider audience and build a loyal customer base.  </h2>
                            <p className='txt-1'>Our digital experts will develop strategic social media marketing campaigns tailored to your brand's unique goals, ensuring maximum exposure and engagement with potential customers.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Drive more traffic</div>
                            <h2 className='mb-16 display-2'>Our user-friendly websites are optimized for search engines, helping your business rank higher </h2>
                            <p className='txt-1'>Our user-friendly websites are optimized for search engines, helping your business rank higher in search results and attract more organic traffic. By carefully considering the user experience, we create websites that encourage visitors to stay longer, explore further, and ultimately convert into loyal customers.</p>
                        </div>
                    </div>
					<div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Expert Knowledge and Support</div>
                            <h2 className='mb-16 display-2'>We bring valuable insights and expertise to every project we undertake.   </h2>
                            <p className='txt-1'>With our deep understanding of the digital landscape, we bring valuable insights and expertise to every project we undertake. Our team provides ongoing support, ensuring that your website and app remain up-to-date, secure, and functioning optimally at all times.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services