import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import FeatureCards from '../../components/featureCards'
import Footer from '../../components/footer'
import TestimonialCards from '../../components/testimonialCards'
import ServiceCards from '../../components/serviceCards'
import PricingCard from '../../components/pricingCard'
import ProjectCards from '../../components/projectCards'
import ContactSection from '../../components/contactSection'
import './style.scss'
import NavBar from "../../components/navbar"

const RealEstate = () => {
    return(
        <>
            <Helmet>
                <title>Stact - App Landing</title>
            </Helmet>
            <NavBar/>
            <div className="app-landing landing-1">
                <Hero />
                <Showcase />
                <Services/>
                <Showcase1 />
                <Counters/>
                <Showcase2 />
                <Pricing/> 
                <Testimonials />
                
                <Contact/> 
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>
            <section className='section-global bg-shade-blue hero'>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <h1 className='mb-16'>Making Real Estate Shine Online</h1>
                            <p className='txt-1 mb-20'>Boosting real estate businesses with websites, marketing, and more.</p>
                            <a href='/'>
                                <img src={require('./assets/playstore_badge.svg').default} alt="playstore" />
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href='/'>
                                <img src={require('./assets/appstore_badge.svg').default} alt="playstore" />
                            </a>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center">
                            <div style={{maxWidth: '485px'}}>
                                <img src={require('./assets/hero_image.png')} className="img-fluid" alt="hero" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Who We Are</div>
                            <h2 className='mb-16 display-2'>Your Real Estate Partner</h2>
                            <p className='txt-1'>We're here to be your trusted partner for all your digital real estate requirements. From eye-catching websites to smart marketing, we've got you covered to stand out in the real estate world.</p>
                        </div>
                    </div>
                    {/* <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Spaces</div>
                            <h2 className='mb-16 display-2'>Create space and share it with your community and friends</h2>
                            <p className='txt-1'>Stact is a free gaming workspace app powered by the cloud - no matter on which platform you are playing or chatting with your friends, you will always have your own gaming space. </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}
const Services = () => {

    const services = [
        {
            name: 'Real Estate Websites',
            info: 'Our designs showcase properties, captivating buyers and sellers with seamless functionality.',
            link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Digital Marketing',
            info: 'Our expert strategies enhance online presence through SEO, PPC, and engaging content.',
            link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Social Media Marketing',
            info: 'Through platforms like Facebook and Instagram, we spark interest and interaction for your listings.',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Agents Digital Business Card',
            info: 'Streamlined real estate agent networking. Elevate your profile with a digital business card for agents.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-1'>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-4">
                            <div className="section-tag mb-8">Real Estate Services</div>
                            <h2 className='mb-16'>Transforming Your Real Estate Ventures </h2>
                            <Link to='/services' className='btn btn-outline btn-arrow'>
                                See all services
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                        <div className="col-lg-8">
                            <div className="row gx-md-5 gy-5">
                                {
                                    services.map((service,i) => 
                                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6"  key={i}>
                                            <ServiceCards data={service} src="landing3"/>
                                        </div>                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase1 = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2"><div className="row gy-4 gx-0 gx-lg-5 ">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Real Estate Websites </div>
                            <h2 className='mb-16 display-2'>Showcasing Properties. Where Awesome Websites Meet Real Excellence</h2>
                            <p className='txt-1'>We create stunning real estate websites that showcase your properties in the best light, capturing the attention of both buyers and sellers. Our designs not only make your listings look amazing but also create a compelling online presence that engages visitors and fosters successful real estate interactions.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Digital Marketing</div>
                            <h2 className='mb-16 display-2'>Real Estate Digital Boost. SEO, PPC, Content, Social Media </h2>
                            <p className='txt-1'>We specialise in digital marketing for real estate agencies, covering SEO, Facebook ads, and Google ads. Through SEO, we boost your online visibility, while Facebook and Google ads target and engage your audience, driving enquiries and transactions.</p>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}

const Counters = () => {
    const counter_data = [
        {
            count: '5K+',
            text: 'Delivered projects'
        },
        {
            count: '43%',
            text: 'Market share'
        },
        {
            count: '75',
            text: 'Awards won'
        },
        {
            count: '3m+',
            text: 'Users worldwide'
        }
    ]
    return (
        <>
            <section className='counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase2 = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2"><div className="row gy-4 gx-0 gx-lg-5 ">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Agents Digital Business Card </div>
                            <h2 className='mb-16 display-2'>One Link, Endless Opportunities</h2>
                            <p className='txt-1'>Consolidate all your real estate links in one place. With just a single link, you can effortlessly share and connect with your clients, streamlining communication and making your services easily accessible. <br/>
Your Digital Business Card acts as a dynamic link in your bio, offering a seamless way to share your professional information, contact details, and a captivating bio.
</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Real Connections</div>
                            <h2 className='mb-16 display-2'>Connect with more buyers and sellers</h2>
                            <p className='txt-1'>We work diligently to make sure that your property listings are prominently featured and easily accessible to people searching online. Through strategic techniques and targeted efforts, we ensure that potential clients find your listings and connect with you smoothly, ultimately enhancing your real estate business. </p>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}


const Pricing = () => {

    const pricing_data = [
        {
            title: 'Real estate websites',
            text: 'Agent & agency website',
            price: 'From $795',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback'],
            not_included: ["Data insights","Employee management"]
        },
        {
            title: 'SEO & Social Media',
            text: 'SEO, social media management',
            price: 'From $395',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback',"Data insights"],
            not_included: ["Employee management"],
            featured: true
        },
        {
            title: 'Paid Ads Management',
            text: 'FB ads, google ads',
            price: 'From $395',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback',"Data insights","Employee management"],
            buttonLabel: 'Contact sales'
        }
    ]
    
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Pricing</div>
                            <h2>Affordable pricing, Premium services.</h2>
                        </div>
                    </div>
                    <div className="row">
                        {pricing_data.map((e,i)=> 
                            <div className="col-lg-4 col-md-6 gy-4" key={i}>
                                <PricingCard data={e}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}
 

const Testimonials = () => {

    const testimonial_data = [
        {
            brandImgUrl: 'assets/review_brand1.svg',
            text: 'Love the product and the service, and the customer care team is awesome. The features are great, too--everything that you need.',
            userName: 'Mary Edwards',
            position: 'Product director',
            userImgUrl: 'assets/review_user1.svg'
        },
        {
            brandImgUrl: 'assets/review_brand2.svg',
            text: 'I recommend this product because it benefits everyone. You will be able to stay connected with your team and clients from all over the world.',
            userName: 'Felisa Rincon',
            position: 'Head of Product',
            userImgUrl: 'assets/review_user2.svg'
        },
        {
            brandImgUrl: 'assets/review_brand3.svg',
            text: 'I was able to get a fully functional online office space that included all the software we needed and it was super cheap!',
            userName: 'Eunice Kennedy',
            position: 'Product director',
            userImgUrl: 'assets/review_user3.svg'
        }
    ]

    return(
        <>
            <section className='section-global bg-shade-green'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Customer reviews</div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all reviews
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} src="appLanding" />
                    </div>
                </div>
            </section>
        </>
    )
}

const Projects = () => {

    const projects_data = [
        {
            category: 'development',
            text: 'LAPI - Single entry point API management',
            imgUrl: 'assets/project1.png',
            color: "#F97316",
            link: '/project-detail'
        },
        {
            category: 'design',
            text: 'How Deko manages to scale using Stact',
            imgUrl: 'assets/project2.png',
            color: "#2D7EF8",
            link: '/project-detail'
        },
        {
            category: 'branding',
            text: "Teno's journey from small to giant",
            imgUrl: 'assets/project3.png',
            color: "#00AA45",
            link: '/project-detail'
        }
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Case Studies</div>
                            <h2>Our recently compeleted projects</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all case studies
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <ProjectCards data={projects_data} src="landing2" />
                    </div>
                </div>
            </section>
        </>
    )
}
const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data}/>
        </>
    )
}
 

export default RealEstate