import { Link } from 'react-router-dom'
import './style.scss'
import HeroImage from './assets/hero_image.svg'
import BrandSection from '../../components/brandSection'
import FeatureCards from '../../components/featureCards'
import NavBar from "../../components/navbar"
import TestimonialCards from '../../components/testimonialCards'
import Helmet from 'react-helmet'
import ServiceCards from '../../components/serviceCards'
import Footer from '../../components/footer'
import PricingCard from '../../components/pricingCard'
import ContactSection from '../../components/contactSection'

const OurProjectsCRM = () => {
    return(
        <>
            <Helmet>
                <title>Stact - Saas Landing</title>
            </Helmet>
            <NavBar/>
            <div className="saas-landing landing-2">
                <Hero/>
                <CaseStudy />
                <Services/>
                <About/>
                <InfoSection/>
                <About/>
                <Pricing/>
                <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {

    

    return(
        <>
            <section className='section-global bg-shade-1 no-border hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <h1 className='mb-16 px-xl-5'>Remote workspace for everyone</h1>
                            <div className="txt-2 mb-20 mx-auto" style={{maxWidth: '450px'}}>Become a part of this digital revolution today with the best in class solutions of stact.</div>
                            <Link to='/' className='btn btn-primary btn-lg'>Get a demo</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link to='/' className='btn btn-outline-primary btn-lg'>Contact sales</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='hero-image-banner-container mb-5 pb-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="hero-image-banner text-center">
                                <img src={HeroImage} className="img-fluid" alt="saas_app" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             
        </>
    )
}

const CaseStudy = () => {
    return(
        <>
            <section className='section-global case-study pt-0'>
                <div className="container container-2">
                    <div className="row">
                       
                        <div className="col-12">
                            <div className="case-study-section" style={{maxWidth:700, margin: '0 auto'}}>                            
                                <h2 className='display-2'>1. What is Deko?</h2>
                                <p className='txt-1'>Why risk it all with cheap outsourcing? Most businesses either go for high end agencies that are a bit boring or low end agencies that don't deliver. We offer a new way, called Stack.</p>
                                <h2 className="display-2">2. What were the majore challnges?</h2>
                                <p className="txt-1">We work at the intersection of media, marketing and creativity. We help big brands navigate digital disruption and harness new technologies to evolve their businesses. What sets Stack apart are our end-to-end capabilities, team-based approach and deep expertise in strategy, creative production, data science and app development.</p>
                                <h2 className="display-2">3. The solution we provided</h2>
                                <p className="txt-1">Our team of strategists, creatives and technologists are passionate about helping brands succeed. We work with customers to develop their unique approach to digital communications, defining the objectives and how to achieve them through the delivery of high impact digital campaigns.
                                <br/><br/>We've crafted Stack so that you can easily stand out with digital brand experiences. Whether it's ecommerce, content marketing or digital strategy services, our team at Stack will help you grow your brand, your way.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Services = () => {

    

    return(
        <>
            <section className='section-global bg-shade-pink'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Services</div>
                            <h2>Best in class innovative tech solutions</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/services' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all services
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>

                            </Link>
                        </div>
                    </div>                   
                </div>
                <div className='float-left w100p mt-5'>
                <div className='container container-2 '>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Cyber Security
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Internet of Things
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Digital Marketing
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Cyber Security
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Internet of Things
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center mb-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Digital Marketing
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Cyber Security
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center ">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Internet of Things
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div class="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg> 
                                    Digital Marketing
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
            </section>
        </>
    )
}

const About = () => {

    const features_data = [
        "Seamless, comprehensive, and cost-effective",
        "Connect with your customers",
        "Streamline your operations"
    ]

    return(
        <>  
            <section className="section-global" style={{border: 'none'}}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 align-self-center d-flex justify-content-center">
                            <div className='video-thumb'>
                                <a href='/'>
                                    <img src={require('./assets/video_thumb.jpg')} className="img-fluid" alt="stact" />
                                    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="39.5" cy="39.5" r="37.5" fill="black"/>
                                        <circle cx="37.5" cy="37.5" r="37" fill="#FFFB9E" stroke="black"/>
                                        <path d="M49.5 37.7021C49.5 38.2851 48.9052 38.7044 48.9052 38.7044L32.2113 49.0452C30.9945 49.8428 30 49.2519 30 47.7407V27.6615C30 26.1464 30.9945 25.5594 32.2133 26.355L48.9072 36.6998C48.9053 36.6998 49.5 37.119 49.5 37.7021Z" fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Who we are</div>
                            <h2 className='mb-16'>We provide digital solutions for your business</h2>
                            <p className='txt-1'>We craft digital experiences that resonate with your audience and grow your business. If you have a vision for the next big thing, we can help you make it happen. </p>
                            {features_data.map((e,i)=> <div key={i} className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                    <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                    <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>


                                {e}
                            </div>)}
                            <div className='mt-16'>
                                <Link to="/about" className='arrow-link'>
                                    Learn more
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='ms-1'>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="currentColor"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const InfoSection = () => {

    const infoData = [
        {
            title: 'Customer Research',
            text: 'We mine data, uncover insights and identify opportunities'
        },
        {
            title: 'User friendly Build',
            text: 'We architect websites and apps to solve user problems'
        },
        {
            title: 'Scalable models',
            text: 'Continuous Delivery pipelines to ensure fast iteration'
        }
    ]

    return(
        <>
            <section className="section-global bg-shade-green">
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Why choose Stact?</div>
                            <h2 className='mb-16'>We provide solutions that make our clients' lives simpler</h2>
                            {infoData.map((e,i)=>
                                <div className='d-flex mb-16' key={i}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                        <rect width="24" height="24" rx="12" fill="#0FA958"/>
                                        <path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div>
                                        <h4 className='mb-0'>{e.title}</h4>
                                        <div className="txt-2">{e.text}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6 text-center align-self-center">
                            <img src={require('./assets/info.png')} className="img-fluid info-banner-image" alt="Why stact" />                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Pricing = () => {

    const pricing_data = [
        {
            title: 'Basic',
            text: 'For personal or small teams',
            price: '$39',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback'],
            not_included: ["Data insights","Employee management"]
        },
        {
            title: 'Pro',
            text: 'For big teams',
            price: '$59',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback',"Data insights"],
            not_included: ["Employee management"],
            featured: true
        },
        {
            title: 'Enterprise',
            text: 'For big organizations',
            price: '$79',
            period: 'month',
            included: ['Enterprise-grade security','Performance Reviews','360º feedback',"Data insights","Employee management"],
            buttonLabel: 'Contact sales'
        }
    ]
    
    return(
        <>
            <section className='section-global pb-3 pt-3' style={{border: 'none'}}>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Pricing</div>
                            <h2>Flexible plans that feet in all of your needs</h2>
                        </div>
                    </div>
                    <div className="row">
                        {pricing_data.map((e,i)=> 
                            <div className="col-lg-4 col-md-6 gy-4" key={i}>
                                <PricingCard data={e}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

 

 

const Contact = () => {

    const contact_data = {
            title_before: 'Get started with',
            title_highlight: "Stact",
            title_after: 'today!',
            text: "One of the best industry service providers with top tier talented individuals.",
            cta_1_link: '/pricing',
            cta_2_link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data} saasVarient />
        </>
    )
}

export default OurProjectsCRM