import { Accordion } from 'react-bootstrap'
import Helmet from 'react-helmet'
import NavBar from "../../components/navbar"
import Footer from '../../components/footer'
import './style.scss'
import ContactSection from '../../components/contactSection'
import { Link } from 'react-router-dom'
import ProjectCards from '../../components/projectCards'

const OurWork  = () => {
    return (
        <>
            <Helmet>
                <title>Stact - OurWork </title>
            </Helmet>
            <NavBar/>
            <div className="faqs-container">
                <Hero/>
                <Projects/>
                {/* <Contact/> */}
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return (
        <>
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">FAQs</div>
                            <h1 className='display-1'>Get answers of all your questions here</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Projects = () => {

    const projects_data = [
        {
            category: 'development',
            text: 'LAPI - Single entry point API management',
            imgUrl: 'assets/project1.png',
            color: "#F97316",
            link: '/project-detail'
        },
        {
            category: 'design',
            text: 'How Deko manages to scale using Stact',
            imgUrl: 'assets/project2.png',
            color: "#2D7EF8",
            link: '/project-detail'
        },
        {
            category: 'branding',
            text: "Teno's journey from small to giant",
            imgUrl: 'assets/project3.png',
            color: "#00AA45",
            link: '/project-detail'
        }
    ]

    return(
        <>
            <section className='section-global'>
                <div className="container">
                    {/* <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Case Studies</div>
                            <h2>Our recently compeleted projects</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all case studies
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div> */}
                    <div className="row">
                        <ProjectCards data={projects_data} src="landing2" />
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title_before: 'Get started with',
            title_highlight: "Stact",
            title_after: 'today!',
            text: "One of the best industry service providers with top tier talented individuals.",
            cta_1_link: '/pricing',
            cta_2_link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data} saasVarient />
        </>
    )
}

export default OurWork 