import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import BlogCards from '../../components/blogCards'
import BrandSection from '../../components/brandSection'
import ProjectCards from '../../components/projectCards'
import FeatureCards from '../../components/featureCards'
import Footer from '../../components/footer'
import PricingCard from '../../components/pricingCard'
import ServiceCards from '../../components/serviceCards'
import TestimonialCards from '../../components/testimonialCards'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Sales Boss</title>
            </Helmet>
            <NavBar/>
            <div className='landing-1'>
                <Hero />
				<Showcase1/>
                <Services />
				 <Features/>
				 <Showcase/>
				 <Testimonials /> 
				 <Showcase2/>
				  <Counters/>
				   <Showcase3/>
                <About />
                       
                <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {

    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/brand1.svg"
        },
        {
            name: "brand2",
            imgUrl: "assets/brand2.svg"
        },
        {
            name: "brand3",
            imgUrl: "assets/brand3.svg"
        },
        {
            name: "brand4",
            imgUrl: "assets/brand4.svg"
        },
        {
            name: "brand5",
            imgUrl: "assets/brand5.svg"
        }
    ]

    return (
        <>
            <section className='section-global no-border bg-shade-blue hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 align-self-center">
                            <h2 className='title-2'>Empowering Your Digital Growth</h2>
                            <h3 className='title-3'>Your One-Stop Digital Agency</h3>
                            <p className='txt-1 mt-16 me-lg-5'>Grow your business with expert website development and effective digital marketing strategies</p>
                          <div  className='mt-4 float-start w-100'>
                            <a to="#" className='btn btn-primary btn-lg'>Get Started</a>
                            <a to="#" className='btn btn-bron btn-lg ms-3'>Explore Services</a>
                            </div>
                        </div>
                        <div className="col-lg-5 align-self-center d-flex justify-content-center text-center">
                            <div style={{maxWidth: '450px'}}>
                                <img src={require('./assets/hero_image.png')} className="img-fluid mt-5 mt-lg-0" alt='hero' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <BrandSection brands={brands} src={'landing1'} bg="bg-shade-blue" light bordered/> */}
        </>
    )
}

const Services = () => {
    const services = [
        {
            name: 'Web Development ',
            info: 'Craft stunning websites with seamless user experience, from simple landing pages to complex applications.',
            link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Ecommerce',
            info: 'Boost online retail success with user-friendly platforms, secure payment gateways, and captivating designs.',
            link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Digital Marketing',
            info: 'Stand out from the competition with data-driven strategies, SEO, content marketing, and social media management.',
            link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Mobile App',
            info: 'Engage customers through powerful, feature-rich apps for iOS and Android platforms.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        },
     {
            name: 'Graphic Designs',
            info: 'Leave a lasting impression with visually striking visuals, logos, and marketing materials.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        },
     {
            name: 'Paid Ads management',
            info: 'Maximize ROI with expertly crafted paid ad campaigns targeting the right audience.',
            link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ]
    return(
          <>
            <section className='section-global bg-shade-1'>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-5">
                            <div className="section-tag mb-8">Services</div>
                            <h2 className='mb-16'>Best-in-class innovative <br/>tech solutions</h2>
                            <Link to='/services' className='btn btn-outline btn-arrow'>
                                See all services
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                        <div className="col-lg-7">
                            <div className="row gx-md-5 gy-5">
                                {
                                    services.map((service,i) => 
                                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6"  key={i}>
                                            <ServiceCards data={service} src="landing3"/>
                                        </div>                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const Features = () => {

    const featurs_data = [
        {
            title: 'Understand',
            text: 'We learn about your business, audience, and trends to plan our approach.'
        },
        {
            title: 'Build',
            text: 'Using insights, our team creates, joining design, development, and marketing.'
        },
        {
            title: 'Scale',
            text: 'After building, we use data to make things even better and reach more people. '
        }
    ]

    return(
        <>
            <section className='section-global pb-0' style={{border: 'none'}}>
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
                            <div className="section-tag mb-8">How we work</div>
                            <h2>Extremely smooth workflow with consistency </h2>
                        </div>
                    </div>
                </div>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-md-5">
                        <FeatureCards data={featurs_data} src='about'/>
                    </div>
                </div>
            </section>
        </>
    )
}



const Showcase1 = () => {

    return(
        <>
            <section className='section-global pt-4' style={{border: 'none'}}>
                <div className="container container-2">
				<br/><br/>
                    {/* <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Communicate</div>
                            <h2 className='mb-16 display-2'>Communicate with other people in the same space</h2>
                            <p className='txt-1'>Cross-platform messaging allows people from iOS/Android to communicate with one another using a universal library of smiley faces, threads, and file sharing. Everyone wins!</p>
                        </div>
                    </div> */}
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
					
					 <div className="col-lg-6">
                            <img src={require('./assets/about.png')} className="img-fluid" alt="feature1" />
                        </div>
						
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Who we are</div>
                            <h2 className='mb-16 display-2'>Providing digital solutions for your business</h2>
                            <p className='txt-1'>Our team of enthusiastic individuals, skilled in creativity, technology, and marketing, work together to elevate your brand through innovative strategies and data-driven insights. SalesBoss helps you get more traffic, acquire more customers, and sell more stuff. 
								 <br/><br/>
								<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Digital Solutions Tailored for Your Success,<br/>
								<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Strategic Focus on ROI and Conversions,<br/>
								<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2"><rect width="24" height="24" rx="12" fill="#0FA958"></rect><path d="M6.66675 12L10.6667 16L17.3334 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> Comprehensive Digital Solutions Under One Roof 
							</p>
     
						</div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}



const Showcase = () => {
return(
        <>
 <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/about1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Our Love for Entrepreneurs</div>
                            <h2 className='mb-16 display-2'>Collaborative Approach</h2>
                            <p className='txt-1'>We believe in teamwork and collaboration. As your digital partner, we work closely with you to understand your goals, challenges, and vision. 

</p>
                        </div>
                    </div>
					<br/><br/><br/>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">Your Vision, Our Skills</div>
                            <h2 className='mb-16 display-2'>Results-Driven Strategies</h2>
                            <p className='txt-1'>Our results-driven approach focuses on measurable outcomes, whether it's increasing your online sales, improving brand visibility, or maximizing ROI on advertising. </p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/about2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                </div>
            </section>
 </>
    )
}



const Counters = () => {
    const counter_data = [
        {
            count: '5K+',
            text: 'Delivered projects'
        },
        {
            count: '43%',
            text: 'Market share'
        },
        {
            count: '75',
            text: 'Awards won'
        },
        {
            count: '3m+',
            text: 'Users worldwide'
        }
    ]
    return (
        <>
            <section className='counter-section dark'>
                <div className="container">
                    <div className="row">
                        {counter_data.map((e,i) => 
                            <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                                <h2 className="display-1">
                                    {e.count}
                                </h2>
                                <p className='txt-1'>{e.text}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Showcase3 = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2"><div className="row gy-4 gx-0 gx-lg-5 ">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            
                            <h2 className='mb-16 display-2'>Save Time and Effort</h2>
                            <p className='txt-1'>Leave the technical aspects to us while you focus on what you do best – running your business. Our team takes care of all aspects of web development and digital marketing, ensuring a hassle-free experience for you. 
</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/about2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
					<br/><br/><br/>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/about1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            
                            <h2 className='mb-16 display-2'>Entrepreneurs & Start-ups</h2>
                            <p className='txt-1'>At Salesboss, entrepreneurs and start-ups hold a special place in our hearts. We thrive on their passion, vision, and innovative ideas. Contact us today to collaborate and bring your idea to life.</p>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    )
}





const About = () => {
    
    const projects_data = [
        {
            category: 'development',
            text: 'LAPI - Single entry point API management',
            imgUrl: 'assets/casestudies-1.png',
            color: "#F97316",
            link: '/project-detail'
        },
        {
            category: 'design',
            text: 'How Deko manages to scale using Stact',
            imgUrl: 'assets/casestudies-2.png',
            color: "#2D7EF8",
            link: '/project-detail'
        },
        {
            category: 'branding',
            text: "Teno's journey from small to giant",
            imgUrl: 'assets/casestudies-3.png',
            color: "#00AA45",
            link: '/project-detail'
        }
    ]
    return(
        <>
          
            <section className='section-global'>
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Case Studies</div>
                            <h2>Our recently compeleted projects</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/projects' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all case studies
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <ProjectCards data={projects_data} src="landing2" />
                    </div>
                </div>
            </section>
        </>
    )
}


const Testimonials = () => {

    const testimonial_data = [
        {
            brandImgUrl: 'assets/review_brand1.svg',
            text: ' Choosing this company for both web development and digital marketing was the best decision I made. They handled everything seamlessly, saving me time and hassle.',
            userName: 'Mary Edwards',
            position: 'Product director',
            userImgUrl: 'assets/review_user1.svg'
        },
        {
            brandImgUrl: 'assets/review_brand2.svg',
            text: 'My website looks amazing, and my online marketing efforts are now more successful than ever. Great content and email campaigns too.',
            userName: 'Felisa Rincon',
            position: 'Head of Product',
            userImgUrl: 'assets/review_user2.svg'
        },
        {
            brandImgUrl: 'assets/review_brand3.svg',
            text: 'Salesboss used the latest tech, making it responsive across devices. Highly recommend their web development services. Our SEO rankings improved and social media strategies brought in more leads. ',
            userName: 'Eunice Kennedy',
            position: 'Product director',
            userImgUrl: 'assets/review_user3.svg'
        }
    ]

    return(
        <>
            <div className="section-global bg-shade-blue">
                <div className="container">
                    <div className="row mb-40 justify-content-between gy-4">
                        <div className="col-xl-5 col-lg-5">
                            <div className="section-tag mb-8">Customer reviews</div>
                            <h2>Adopted by the most creative individuals</h2>
                        </div>
                        <div className="col d-flex align-self-center">
                            <Link to='/' className='btn btn-outline btn-arrow ms-lg-auto'>
                                See all reviews
                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z" fill="black"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <TestimonialCards data={testimonial_data} src="landing2" />
                    </div>
                </div>
            </div>
        </>
    )
}
 
 
const Showcase2 = () => {
    return(
        <>
            <section className='section-global' style={{border: 'none'}}>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/about1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            
                            <h2 className='mb-16 display-2'>All-in-one CRM </h2>
                            <p className='txt-1'>With our websites, you not only get an attractive online presence, but also the added advantage of the SalesBoss CRM. This powerful platform simplifies contact, lead, and marketing management, making it effortless for businesses to stay organized and efficiently manage their customer relationships. </p>
                        </div>
                    </div>
					<br/><br/><br/>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            
                            <h2 className='mb-16 display-2'>Enhanced Online Presence</h2>
                            <p className='txt-1'>With our digital services, your brand will gain increased online visibility, making it easier for potential customers to find and engage with your products or services. Our expert team will optimize your website and implement effective SEO strategies to improve your search engine rankings.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/about2.png')} className="img-fluid" alt="feature1" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
 
 
 

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
        </>
    )
}

export default HomePage