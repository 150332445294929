import Helmet from 'react-helmet'
import FeatureCards from '../../components/featureCards'
import Footer from '../../components/footer'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'
import ServiceCards from '../../components/serviceCards'
import Landing1 from '../landing1'

const About = () => {
    return(
        <>
            <Helmet>
                <title>About Us</title>
            </Helmet>
            <NavBar/>
            <div className="about-container landing-3 saas-landing">
                <Hero />
                <AboutSection />
                <Counters /> 
                <Services />
                <Culture/> 
                <Team />
                <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    return(
        <>
            <section className='section-global bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-tag mb-8">About Us</div>
                            <h1 className='display-1'>Experience the SalesBoss advantage - Your Pathway to Digital Brilliance</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const AboutSection = () => {
    const capabilities = [
        {
            title: 'Enterprise solutions',
            text: 'Top-notch solutions provider in the industry'
        },
        {
            title: 'Technology services',
            text: 'Top-notch solutions provider in the industry'
        }
    ]
    const counter_data = [
        {
            count: '5K+',
            text: 'Projects'
        },
        {
            count: '75',
            text: 'Awards'
        },
        {
            count: '3m+',
            text: 'Users'
        }
    ]
    return(
        <>
            <section className='section-global'>
                <div className="container">
                    <div className="row gy-4">
                    <div className="col-lg-6 align-self-center d-flex justify-content-center">
                            <div className="about-img-container">
                                <a href='/'>
                                    <img src={require(`./assets/about.jpg`)} className="img-fluid" alt='about'/>
                                    <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="39.5" cy="39.5" r="37.5" fill="black"/>
                                        <circle cx="37.5" cy="37.5" r="37" fill="#FFFB9E" stroke="black"/>
                                        <path d="M49.5 37.7021C49.5 38.2851 48.9052 38.7044 48.9052 38.7044L32.2113 49.0452C30.9945 49.8428 30 49.2519 30 47.7407V27.6615C30 26.1464 30.9945 25.5594 32.2133 26.355L48.9072 36.6998C48.9053 36.6998 49.5 37.119 49.5 37.7021Z" fill="black"/>
                                    </svg>
                                </a>
                                <div className="counter-section d-flex justify-content-between bg-shade-blue">
                                    {counter_data.map((e,i)=> 
                                        <div className="count text-center" key={i}>
                                            <h2 className='mb-0'>{e.count}</h2>
                                            <div className="txt-2">{e.text}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Who we are</div>
                            <h2 className='mb-16'>Best in class innovative tech solutions</h2>
                            <p className='txt-1 mb-20'>Salesboss, is a dynamic force driving digital evolution. With a passion for innovation and a team of creative minds, we're committed to shaping your digital aspirations. Our company is proud to offer the best in class innovative tech solutions. We are constantly pushing the boundaries of technology to deliver cutting-edge products and services to our customers.</p>
                            <h3 className='mb-16'>Our core capabilities</h3>
                            <div>
                                <div className="row gy-4">
                                  
                                        <div className="col-xl-6 col-lg-12 col-md-6" >
                                            <div className="d-flex capability-card" >
                                                <div className="count">1</div>
                                                <div className="ms-2">
                                                    <div className="txt-2 fw-700 color-1">Creative Design</div>
                                                    <div className="txt-3">We make stunning websites that tell your brand's story.</div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                        
                                        <div className="col-xl-6 col-lg-12 col-md-6" >
                                            <div className="d-flex capability-card" >
                                                <div className="count">2</div>
                                                <div className="ms-2">
                                                    <div className="txt-2 fw-700 color-1">Strategic Marketing</div>
                                                    <div className="txt-3">We connect your brand with the right audience through effective campaigns.</div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>    

                    
                </div>
                <div className="section-global-1 pb-2">
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
<div className="col-lg-6 order-1 order-lg-0 align-self-center">
    <div className="section-tag mb-8">What we do?</div>
   
    <p className='txt-1'>We're your all-in-one digital solution. We offer web development, ecommerce, digital marketing, mobile app development, graphic design, and paid ads management. We design stunning websites that catch eyes and create apps that fit in your pocket. </p>
    
<p className='txt-1'>Our smart marketing strategies make your brand known, and we manage social marketing ads that get you noticed. Whether you're new or already in business, Salesboss is your partner for digital success. </p>    
    
</div>
<div className="col-lg-6">
    <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
</div>
</div>
</div>
</div>
            </section>

     

           
        </>
    )
}


const Counters = () => {
    const counter_data = [

    ]
    return(
        <>
            
        </>
    )
}

const Services = () => {
    const services = [
        {
            name: 'Web Development',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Ecommerce Solutions',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Digital Marketing',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'Mobile Apps',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        },
        {
            name: 'Graphic Designs',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico1.svg'
        },
        {
            name: 'Paid Ads Management',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico2.svg'
        },
        {
            name: 'Real Estate',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico3.svg'
        },
        {
            name: 'SalesBoss CRM',
            // info: 'Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.',
            // link: '/services',
            icoUrl: 'assets/service_ico4.svg'
        }
    ]
    return(
        <>
            <section className="section-global bbn">
                <div className="container">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Services</div>
                            <h2>Best in class innovative tech solutions</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            services.map((service,i) => 
                                <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"  key={i}>
                                    <ServiceCards data={service} src="landing1"/>
                                </div>                    
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
} 

const Culture = () => {

    const culture_data = [
        {
            imgUrl: './assets/culture_1.png',
            title: 'Industry Experts',
            text: 'Our team comprises seasoned industry experts, bringing extensive knowledge to every project.'
        },
        {
            imgUrl: './assets/culture_2.png',
            title: 'Growth oriented',
            text: 'Fuelled by a passion for progress, we are committed to continual learning and pushing our limits.'
        },
        {
            imgUrl: './assets/culture_3.png',
            title: 'Team spirit',
            text: 'Collaboration is our cornerstone; together, we harness collective strengths to achieve remarkable outcomes.'
        }
    ]

    return(
        <>
            <section className="section-global pt-0">
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2 text-center">
                            <div className="section-tag mb-8">Culture</div>
                            <h2>Industry Experts, Growth Oriented, and United in Team Spirit</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            culture_data.map((e,i)=> 
                                <div className="col-lg-4 col-md-6 col-sm-6 text-center" key={i}>
                                    <img src={require(`${e.imgUrl}`)} className="img-fluid" alt={e.title}/>
                                    <h3>{e.title}</h3>
                                    <p className="txt-2">{e.text}</p>
                                </div>
                            )
                        }
					
                    </div>
                </div>
            </section>
        </>
    )
}


const Team = () => {
    const team_data = [
        {
            name: 'Prit Pal',
            position: 'Founder & CEO',
            imgUrl: 'assets/team1.jpg'
        },
        {
            name: 'Rohit Bhatia',
            position: 'Chief Technology Officer CTO',
            imgUrl: 'assets/team2.jpg'
        },
        {
            name: 'Pavi Singh',
            position: 'UI/UX Designer',
            imgUrl: 'assets/team3.jpg'
        },
        {
            name: 'Piyush',
            position: 'Full Stack Developer',
            imgUrl: 'assets/team4.jpg'
        }
    ]
    return(
        <>
            <section className='section-global bg-shade-blue'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-lg-8">
                            <div className="section-tag mb-8">Team</div>
                            <h2 className='mb-16'>A team of skilled idividuals that helps you to grow</h2>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {team_data.map((e,i)=>
                            <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="team-member text-center">
                                    <img src={require(`./${e.imgUrl}`)} className="img-fluid mb-16" alt={e.name} />
                                    <div className="txt-2 color-1 fw-500">{e.name}</div>
                                    <div className="txt-3">{e.position}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

const Contact = () => {

    const contact_data = {
            title: 'Have an idea?',
            title_highlight: "Let's talk",
            text: "One of the best industry service providers with top tier talented individuals.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection contact_data={contact_data}/>
        </>
    )
}

export default About