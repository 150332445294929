import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import BrandSection from '../../components/brandSection'
import Footer from '../../components/footer'
import ProjectCards from '../../components/projectCards'
import ServiceCards from '../../components/serviceCards'
import TestimonialCards from '../../components/testimonialCards'
import PricingCard from '../../components/pricingCard'
import './style.scss'
import NavBar from "../../components/navbar"
import ContactSection from '../../components/contactSection'
 
const OurProducts = () => {
    return(
        <>
            <Helmet>
                <title>Stact - Landing2</title>
            </Helmet>
            <NavBar/>
            <div className='landing-2 saas-landing'>
                <Hero />
                <Showcase /> 
                <Hero1/>
                <Pricing />
               <Contact />
                <Footer/>
            </div>
        </>
    )
}

const Hero = () => {
    const counters = [
        {
            count: '2K+',
            text: "Agents"
        },
        {
            count: '5K+',
            text: "Bio Pages"
        },
        {
            count: '15+',
            text: "Countries"
        }
    ]
    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/brand1.svg"
        },
        {
            name: "brand2",
            imgUrl: "assets/brand2.svg"
        },
        {
            name: "brand3",
            imgUrl: "assets/brand3.svg"
        },
        {
            name: "brand4",
            imgUrl: "assets/brand4.svg"
        },
        {
            name: "brand5",
            imgUrl: "assets/brand5.svg"
        }
    ]
    return(
        <>
            <section className='section-global no-border bg-shade-1 hero'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <h1 className='mb-16'>Digital business card for real estate agents</h1>
                            <p className='txt-1 mb-16'>Enhance networking and client connections with your personalised digital business card </p>
                            <Link to='/' className='btn btn-primary btn-lg'>Get a demo</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link to='/' className='btn btn-outline-primary btn-lg'>Learn more</Link>
                            <div className="counter mt-20 d-flex flex-wrap">
                                {counters.map((e,i)=>
                                    <div className="count txt-center" key={i}>
                                        <h2 className='mb-0'>{e.count}</h2>
                                        <div className='txt-2'>{e.text}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center d-flex justify-content-center">
                            <div style={{maxWidth: '500px'}}>
                                <img src={require('./assets/hero_image.png')} className="img-fluid mt-5 mt-lg-0" alt='hero' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BrandSection brands={brands} src={'landing2'} bg="bg-shade-1" light bordered/>
        </>
    )
}

const Showcase = () => {
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row gy-4 gx-0 gx-lg-5">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">Welcome to Agents.bio</div>
                            <h2 className='mb-16 display-2'>Elevate your real estate profile with a dynamic Bio link</h2>
                            <p className='txt-1'>At Agents.Bio, we're on a mission to revolutionise how real estate agents connect, present their services, and conduct business. We understand the unique challenges you face in an ever-evolving industry, which is why we've designed a platform tailored exclusively for you.<br/><br/>

Agents.Bio is a one-stop solution for real estate agents to effortlessly showcase their diverse services to clients using a single link. 
</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">What is Agents.bio?</div>
                            <h2 className='mb-16 display-2'>The power of a digital business card for real estate agents </h2>
                            <p className='txt-1'>We get it – managing multiple platforms and sharing various links can be overwhelming. Agents.Bio simplifies everything by consolidating all your essential real estate content into one centralised hub. Imagine having a single link that effortlessly leads clients to your professional profile, listings, reviews, and more. No more juggling between websites; Agents.Bio brings it all together.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase1.png')} className="img-fluid" alt="feature1" />
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-tag mb-8">More exposure</div>
                            <h2 className='mb-16 display-2'>Showcase your expertise</h2>
                            <p className='txt-1'>Your bio is your digital calling card. Agents.Bio enhances your profile's discoverability, making sure your expertise shines. Create personalised bio link pages that capture your unique style. Highlight your achievements, showcase your listing links, and seamlessly integrate your presence on popular real estate platforms like Realestate, Ratemyagent, Trulia, Rightmove, Zillow, and social media.</p>
                        </div>
                    </div>
                    <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
                        <div className="col-lg-6 order-1 order-lg-0 align-self-center">
                            <div className="section-tag mb-8">QR codes</div>
                            <h2 className='mb-16 display-2'>Effortless sharing with QR Codes </h2>
                            <p className='txt-1'>We believe in making connections simple. Agents.Bio lets you create custom QR codes that link directly to your bio page. Share these QR codes via email, text, or social media, offering clients an instant gateway to your services. It's a game-changer for in-person networking and quick access.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={require('./assets/showcase2.png')} className="img-fluid" alt="feature2" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Hero1 = () => {

    const brands = [
        {
            name: "brand1",
            imgUrl: "assets/brand1.svg"
        },
        {
            name: "brand2",
            imgUrl: "assets/brand2.svg"
        },
        {
            name: "brand3",
            imgUrl: "assets/brand3.svg"
        },
        {
            name: "brand4",
            imgUrl: "assets/brand4.svg"
        },
        {
            name: "brand5",
            imgUrl: "assets/brand5.svg"
        }
    ]

    return(
        <>
           
            <BrandSection brands={brands} src={'consulting'} bordered/>
        </>
    )
}

const Pricing = () => {

    const pricing_data = [
        {
            title: 'FREE',
            text: 'Free Forever',
            price: '$0',
            period: 'month',
            included: ['Biolink Pages', 'Biolink Blocks', 'Payment Processors', 'Shortened Links'],
            not_included: ["Deep linking", "Removable branding"]
        },
        {
            title: 'Yearly',
            text: 'Renews every year',
            price: '$64.95',
            period: 'year',
            included: ['Custom Back-half', 'Deep linking', 'Removable branding', 'Custom branding', 'Dofollow links', 'Leap link'],
          
            featured: true
        },
        {
            title: 'Lifetime',
            text: 'One-time payment for life',
            price: '$195',
            period: 'Lifetime',
            included: ['Custom Back-half', 'Deep linking', 'Removable branding', 'Custom branding', 'Dofollow links', 'Leap link'],
            buttonLabel: 'Contact sales'
        }
    ]
    
    return(
        <>
            <section className='section-global'>
                <div className="container container-2">
                    <div className="row mb-40">
                        <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                            <div className="section-tag mb-8">Pricing</div>
                            <h2>Find the perfect plan that fits your every need</h2>
                        </div>
                    </div>
                    <div className="row">
                        {pricing_data.map((e,i)=> 
                            <div className="col-lg-4 col-md-6 gy-4" key={i}>
                                <PricingCard data={e}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}


 
 
 
 

const Contact = () => {

    const contact_data = {
            title: 'Get started with Agents.bio today!',
            title_highlight: "Let's talk",
            text: "Join now to unlock your agent potential and simplify your real estate journey.",
            link: '/contact'
        }

    return(
        <>
            <ContactSection bg="bg-shade-blue" contact_data={contact_data}/>
        </>
    )
}

export default OurProducts